import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { Observer } from "mobx-react";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import "../styles.scss";
import {
  Header,
  PageSidebar,
  ApplicationFormProgress,
  applicationStore,
  PrimaryInformationCard,
  userStore,
  reactPluginInstance,
} from "@mdr-ui/core";
import logo from "../../assets/Medicaid-Done-Right-Logo.png";
import variables from "../../config/variables";

const NewApplicationFormComponent: React.FC = () => {
  const navigate = useNavigate();

  return (
    <div className="page application-form-page">
      {/* Dont want page level observer */}
      <Observer>
        {() => (
          <Header
            logo={logo}
            breadCrumbs={[
              { text: "", children: <Link to="/">Board</Link> },
              {
                text: "Create Application",
              },
            ]}
          />
        )}
      </Observer>
      <div className="content">
        <PageSidebar
          activePage="home"
          featureToggleConfig_matchups={variables.featureToggleConfig.matchups}
        />
        <div className="page-main-content">
          <ApplicationFormProgress
            hasMedicalCoverage={false}
            hasSpouse={false}
            currentPage="primary-information"
            pageMissingFieldsCounters={applicationStore.pageMissingFieldCount}
          />
          <div className="application-card">
            <PrimaryInformationCard
              canEdit={!!userStore.user?.canCreateApplication}
              submitForm={async (application) => {
                await applicationStore.createApplication(application);
                navigate(
                  `/application-form/${applicationStore.application?.id}/primary-information`
                );
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export const NewApplicationForm = withAITracking(
  reactPluginInstance,
  NewApplicationFormComponent,
  "NewApplicationForm",
  "AI-flex-container"
);
