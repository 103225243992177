import React, { useEffect } from "react";
import { Navigate, useOutlet } from "react-router";
import "./styles.scss";
import { autorun } from "mobx";
import { ErrorBoundary } from "react-error-boundary";
import { Dialogs as UCLDialogs } from "@ucl/library";
import {
  errorStore,
  Dialogs as MDRDialogs,
  ErrorWrapper,
  useInterval,
  dailyDebriefAutoOpenAppliesToUser,
  isDailyDebriefAutoOpenTime,
  checkIfShouldAutoOpenDailyDebriefDialog,
} from "@mdr-ui/core";
export interface PagesLayoutComponentProps {
  redirectPath: string;
}

const PagesLayoutComponent: React.FC<PagesLayoutComponentProps> = (props) => {
  const outlet = useOutlet();
  const [hasError, setHasError] = React.useState<boolean>(false);

  useEffect(() => {
    autorun(() => {
      setHasError(!!errorStore.errorMessage);
    });

    if (dailyDebriefAutoOpenAppliesToUser()) {
      checkIfShouldAutoOpenDailyDebriefDialog();
    }
  }, []);

  useInterval(() => {
    if (dailyDebriefAutoOpenAppliesToUser() && isDailyDebriefAutoOpenTime()) {
      checkIfShouldAutoOpenDailyDebriefDialog();
    }
  }, 60_000);

  return (
    <>
      <div className="page">
        {hasError && (
          <ErrorWrapper
            error={undefined}
            resetErrorBoundary={() => {
              // do nothing
            }}
          />
        )}
        {!hasError && (
          <ErrorBoundary FallbackComponent={ErrorWrapper}>
            <>{outlet || <Navigate to={props.redirectPath} replace />}</>
          </ErrorBoundary>
        )}
      </div>
      <MDRDialogs />
      <UCLDialogs />
    </>
  );
};

export const PagesLayout = PagesLayoutComponent;
