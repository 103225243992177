import classnames from "classnames";
import { Icon, Intent } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import {
  Application,
  EligibilitySummaryResponseDTO,
  labelWithIcon,
  spendDownIconColor,
  progressTooltip,
  EligibilitySummaryProgressLabel,
  expandedTooltip,
  expandedIncomeItems,
  tooltip,
  incomeItems,
  currency,
  expandedAssetItems,
  assetItems,
  AMDAssetEligibilityDisclaimerIcon,
  estPatientLiabilityItems,
  dateTooltip,
  applyByDateTimelineItem,
  formatDateToMMddYYYY,
  getAssetValueToDisplay,
} from "@mdr-ui/core";

export interface EligibilityBannerProps {
  application: Application;
  eligibilitySummary: EligibilitySummaryResponseDTO;
}

const EligibilityBanner: React.FC<EligibilityBannerProps> = (props) => {
  return (
    <div>
      <div className="eligibility-summary-banner">
        {props.application.isMarried &&
          !props.application.isSpouseLTCFacility &&
          props.application.overrideAssetEligibility && (
            <div className="eligibility-summary-data-point">
              {labelWithIcon(
                "",
                "Manager confirmed Resident/community spouse spenddown complete. Disregard Asset Eligibility display text.",
                <Icon
                  icon={IconNames.InfoSign}
                  intent={Intent.DANGER}
                  size={35}
                  color={spendDownIconColor}
                />
              )}
            </div>
          )}

        <div className="eligibility-summary-data-point">
          {labelWithIcon(
            "Eligibility",
            <div
              dangerouslySetInnerHTML={{
                __html:
                  props.eligibilitySummary.medicaidEligibilityDecision
                    ?.decisionType_AsString || "",
              }}
            />,
            <Icon
              icon={IconNames.Issue}
              intent={
                props.eligibilitySummary.medicaidEligibilityDecision?.isEligible
                  ? Intent.SUCCESS
                  : Intent.DANGER
              }
            />
          )}
          <div
            className={classnames({
              "not-eligible":
                !props.eligibilitySummary.medicaidEligibilityDecision
                  ?.isEligible,
              eligible:
                props.eligibilitySummary.medicaidEligibilityDecision
                  ?.isEligible,
            })}
          >
            {props.eligibilitySummary.medicaidEligibilityStatus_AsString}
          </div>
        </div>
        <div className="eligibility-summary-data-point">
          {labelWithIcon(
            "Non-Financial",
            progressTooltip(
              Object.keys(
                props.eligibilitySummary.nonFinancialCalculations
                  .nonFinancialCalculations
              ).map((key) => ({
                name: key,
                value: props.eligibilitySummary.nonFinancialCalculations
                  .nonFinancialCalculations[key]
                  ? "Complete"
                  : "Not Complete",
              }))
            )
          )}
          <EligibilitySummaryProgressLabel
            componentId="eligibility-summary-non-financial"
            progress={
              props.eligibilitySummary.nonFinancialCalculations.percentComplete
            }
            completedItems={Object.values(
              props.eligibilitySummary.nonFinancialCalculations
                .nonFinancialCalculations
            ).reduce((count: number, value) => count + (value ? 1 : 0), 0)}
            totalItems={
              Object.keys(
                props.eligibilitySummary.nonFinancialCalculations
                  .nonFinancialCalculations
              ).length
            }
          />
        </div>
        <div className="eligibility-summary-data-point">
          {labelWithIcon(
            "Income",
            props.application.includeAllIncomesInSummary
              ? expandedTooltip(expandedIncomeItems(props.application))
              : tooltip(
                  incomeItems(props.application),
                  props.application.combinedIncomeItemsTotal,
                  "Resident"
                )
          )}
          <div
            className={classnames({
              "not-eligible":
                !!props.eligibilitySummary.medicaidEligibilityDecision &&
                !!props.eligibilitySummary.medicaidEligibilityDecision
                  .residentIncomeExceedsStateThreshold,
              eligible:
                !!props.eligibilitySummary.medicaidEligibilityDecision &&
                !props.eligibilitySummary.medicaidEligibilityDecision
                  .residentIncomeExceedsStateThreshold,
            })}
          >
            {currency(props.application.combinedIncomeItemsTotal || 0)}
          </div>
        </div>
        <div className="eligibility-summary-data-point">
          <div className="multiple-tooltip-banner">
            {labelWithIcon(
              "Assets",
              props.application.includeAllAssetsInSummary
                ? expandedTooltip(expandedAssetItems(props.application))
                : tooltip(
                    assetItems(props.application),
                    getAssetValueToDisplay(props.application),
                    "Resident"
                  )
            )}
            <AMDAssetEligibilityDisclaimerIcon
              medicaidEligibilityDecision={
                props.eligibilitySummary.medicaidEligibilityDecision
              }
            />
          </div>

          <div
            className={classnames({
              "not-eligible":
                !!props.eligibilitySummary.medicaidEligibilityDecision &&
                !!props.eligibilitySummary.medicaidEligibilityDecision
                  .residentAssetsExceedsStateThreshold,
              eligible:
                !!props.eligibilitySummary.medicaidEligibilityDecision &&
                !props.eligibilitySummary.medicaidEligibilityDecision
                  .residentAssetsExceedsStateThreshold,
            })}
          >
            {currency(getAssetValueToDisplay(props.application) || 0)}
          </div>
        </div>
        <div className="eligibility-summary-data-point">
          {labelWithIcon(
            "Est. Patient Liability",
            tooltip(
              estPatientLiabilityItems(props.application),
              props.application?.estPatientLiabilityItemsTotal,
              "",
              "Est. Patient Liability"
            )
          )}
          <div>
            {currency(props.application?.estPatientLiabilityItemsTotal || 0)}
          </div>
        </div>
        <div className="eligibility-summary-data-point">
          {labelWithIcon(
            "Apply By",
            dateTooltip(
              applyByDateTimelineItem(props.application).map((item) => ({
                name: item.name,
                description: item.description,
                value: item.value,
              }))
            )
          )}

          <div>
            {formatDateToMMddYYYY(props.application?.applyDeadlineDate || "")}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EligibilityBanner;
