import { Observer } from "mobx-react";
import {
  Header,
  PageSidebar,
  reactPluginInstance,
  MatchupGridV2,
  userStore,
  MatchupFlyout,
} from "@mdr-ui/core";
import React, { useEffect, useState } from "react";
import "./styles.scss";
import logo from "../assets/Medicaid-Done-Right-Logo.png";
import { withAITracking } from "@microsoft/applicationinsights-react-js";
import variables from "../config/variables";
import { openFlyoutInPlace, useFlyout } from "@ucl/library";
import { useOutlet } from "react-router";

const MatchupPageComponent: React.FC = () => {
  const outlet = useOutlet();
  const { isOpen, openFlyout, closeFlyout } = useFlyout();
  const [matchupId, setMatchupId] = useState<string>();

  useEffect(() => {
    if (matchupId) {
      openFlyout();
    }

    return () => {
      closeFlyout();
    };
  }, [matchupId]);

  const onRecordClick = (matchupId: string) => {
    setMatchupId(matchupId);
  };

  return (
    <div className="page board-page">
      <Observer>{() => <Header logo={logo} />}</Observer>
      <div className="content">
        <PageSidebar
          activePage="matchup"
          featureToggleConfig_matchups={variables.featureToggleConfig.matchups}
        />
        <div className="page-main-content">
          <section className="board-grid-container"></section>
          <MatchupGridV2
            canConfigureBoard={true}
            canCreateNewRecord={false}
            canExportGridToExcel={true}
            canInvokeGridRecord={true}
            canImportRecords={userStore.user?.canImportMatchups || false}
            onRecordClick={onRecordClick}
          />
          {isOpen &&
            openFlyoutInPlace(
              <MatchupFlyout
                matchupId={String(matchupId)}
                flyoutIsOpen={true}
                onClose={() => {
                  closeFlyout();
                  setMatchupId(undefined);
                }}
              />,
              isOpen,
              () => {
                closeFlyout();
                setMatchupId(undefined);
              },
              { canOutsideClickClose: true }
            )}
          {outlet}
        </div>
      </div>
    </div>
  );
};

export const MatchupPage = withAITracking(
  reactPluginInstance,
  MatchupPageComponent,
  "MatchupPage",
  "AI-flex-container"
);
