import { Tag } from "@blueprintjs/core";
import React from "react";

export interface ApplicationFormTabProps {
  name: string;
  shouldHide?: boolean;
  missingFieldCount?: number;
}

export const ApplicationFormTab: React.FC<ApplicationFormTabProps> = ({
  name,
  shouldHide,
  missingFieldCount,
}) => {
  if (shouldHide) {
    return null;
  }
  return (
    <div className="application-form-tab">
      {name}
      {(missingFieldCount || 0) > 0 && (
        <Tag round={true}>{missingFieldCount}</Tag>
      )}
    </div>
  );
};
