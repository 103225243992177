import { InteractionType } from "@azure/msal-browser";
import {
  MsalAuthenticationTemplate,
  MsalProvider,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import {
  msalInstance,
  useTitle,
  FieldSchemaV2FactoryProvider,
} from "@mdr-ui/core";
import { matchPath, RouterProvider } from "react-router-dom";
import "./styles.scss";
import {
  anonymousBrowserRouter,
  anonymousRoutes,
  browserRouter,
} from "../routes/routeConfig";
import { useAuthPageInit } from "./useAuthPageInit";
// This site has 3 pages, all of which are rendered
// dynamically in the browser (not server rendered).
//
// Although the page does not ever refresh, notice how
// React Router keeps the URL up to date as you navigate
// through the site. This preserves the browser history,
// making sure things like the back button and bookmarks
// work properly.

export default function AuthenticatedPages() {
  // Get path and compare to route object path to see if they match
  const currentPath = window.location.pathname;
  const isAnonymousRoute = anonymousRoutes.some((route) => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return matchPath({ path: route.path! }, currentPath);
  });

  return (
    <>
      {!isAnonymousRoute ? (
        <MsalProvider instance={msalInstance}>
          <MsalAuthenticationTemplate
            interactionType={InteractionType.Redirect}
          >
            <Pages />
          </MsalAuthenticationTemplate>
        </MsalProvider>
      ) : (
        <UnauthenticatedTemplate>
          <RouterProvider router={anonymousBrowserRouter} />
        </UnauthenticatedTemplate>
      )}
    </>
  );
}

function Pages() {
  const { isLoaded } = useAuthPageInit();

  useTitle("Medicaid Done Right");

  return isLoaded ? (
    <FieldSchemaV2FactoryProvider>
      <RouterProvider router={browserRouter} />
    </FieldSchemaV2FactoryProvider>
  ) : null;
}
