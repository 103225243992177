import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { Observer } from "mobx-react";
import React from "react";
import "../styles.scss";
import logo from "../../assets/Medicaid-Done-Right-Logo.png";
import {
  Header,
  PageSidebar,
  TemplateGrid,
  userStore,
  reactPluginInstance,
} from "@mdr-ui/core";
import config from "../../config/variables";

const TemplatesPageComponent: React.FC = () => {
  return (
    <div className="page board-page">
      {/* Dont want page level observer */}
      <Observer>{() => <Header logo={logo} />}</Observer>
      <div className="content">
        <PageSidebar
          activePage="templates"
          featureToggleConfig_matchups={config.featureToggleConfig.matchups}
        />
        <div className="page-main-content">
          <TemplateGrid
            apiBaseUrl={config.apiBaseUrl}
            canConfigureBoard={!!userStore.user?.canConfigureTemplateBoard}
            canExportGridToExcel={
              !!userStore.user?.canExportTemplateGridToExcel
            }
            canInvokeGridRecord={!!userStore.user?.canEditTemplate}
            canCreateNewRecord={!!userStore.user?.canCreateTemplate}
          />
        </div>
      </div>
    </div>
  );
};

export const TemplatesPage = withAITracking(
  reactPluginInstance,
  TemplatesPageComponent,
  "TemplatesPage",
  "AI-flex-container"
);
