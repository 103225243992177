import { Button, Icon, Intent } from "@blueprintjs/core";
import logo from "../../assets/Medicaid-Done-Right-Logo.png";
import { IconNames } from "@blueprintjs/icons";
import classNames from "classnames";
import { Observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "../styles.scss";
import "./styles.scss";
import {
  ExistingTemplate,
  templateApiClient,
  errorStore,
  genericErrorMessage,
  AppToaster,
  Header,
  dialogsViewerStore,
  PageSidebar,
  SaveChangeDialog,
  TemplateDesigner,
  templatesFolderName,
  TemplateDesignerStore,
} from "@mdr-ui/core";
import varialbes from "../../config/variables";

export interface TemplateDetailsProps {
  templateId: string;
}
export const TemplateDetails: React.FC<TemplateDetailsProps> = ({
  templateId,
}) => {
  const [template, setTemplate] = useState<ExistingTemplate>();

  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      // fetch
      const fechedTemplate = await templateApiClient
        .getById(templateId)
        .catch(() => {
          errorStore.setErrorMessage(genericErrorMessage);
        });

      // set
      fechedTemplate && setTemplate(fechedTemplate);
    })();

    return () => {
      TemplateDesignerStore.templateDesignerStore.reset();
    };
  }, []);

  const saveFileFormData = async (fileFormData?: string) => {
    await templateApiClient
      .updateFileFormData(templateId, {
        id: templateId,
        fileFormData: fileFormData,
      })
      .then(() => {
        AppToaster.show({
          message: (
            <div>
              <h3>Template Saved</h3>
            </div>
          ),
          intent: Intent.SUCCESS,
        });
      })
      .catch(() => {
        AppToaster.show({
          message: <div>{genericErrorMessage}</div>,
          intent: Intent.DANGER,
        });
      });
  };

  const deleteTemplate = async () => {
    await templateApiClient
      .softDeleteTemplate(templateId)
      .then(() => {
        AppToaster.show({
          message: (
            <div>
              <h3>Template Deleted</h3>
            </div>
          ),
          intent: Intent.SUCCESS,
        });
      })
      .catch(() => {
        AppToaster.show({
          message: <div>{genericErrorMessage}</div>,
          intent: Intent.DANGER,
        });
      });

    // Redirect
    navigate("/templates");
  };

  const templateDesignerChanged = () =>
    TemplateDesignerStore.templateDesignerStore.hasAnnotationsModified;

  return (
    <div className="page template-details">
      <Observer>
        {() => (
          <Header
            logo={logo}
            breadCrumbs={[
              { text: <Link to="/templates">Templates</Link> },
              {
                text: (
                  <>
                    <span
                      className={classNames("template-details_header-text", {
                        "bp4-skeleton": !template,
                      })}
                    >
                      {template?.templateName}
                    </span>
                    {template && (
                      <>
                        <Button
                          className="template-details__edit-button"
                          icon={<Icon icon={IconNames.Edit} iconSize={14} />}
                          minimal={true}
                          intent={Intent.PRIMARY}
                          onClick={() => {
                            dialogsViewerStore.openTemplateUpdateDialog({
                              templateId: template.id,
                              onSubmit(args?: ExistingTemplate) {
                                if (!!args) {
                                  setTemplate(args);
                                }
                              },
                            });
                          }}
                        />
                      </>
                    )}
                  </>
                ),
              },
            ]}
          />
        )}
      </Observer>
      <div className="content">
        <PageSidebar
          activePage="templates"
          featureToggleConfig_matchups={varialbes.featureToggleConfig.matchups}
        />
        <div className="page-main-content">
          {template && (
            <>
              <SaveChangeDialog
                isDirty={true}
                shouldBlockNavigation={templateDesignerChanged}
              />
              <TemplateDesigner
                key={template.documentId}
                fileFormData={template.fileFormData}
                fileDocument={{
                  fileName: template.fileName,
                  folderName: templatesFolderName,
                  isChunked: true,
                }}
                onSave={saveFileFormData}
                onDelete={deleteTemplate}
                apiBaseUrl={varialbes.apiBaseUrl}
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
};
