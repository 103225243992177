import { useAccount } from "@azure/msal-react";
import { useEffect, useState } from "react";
import { googleTagManagerArgs } from "../config/googleTagManager";
import TagManager from "react-gtm-module";
import {
  featureToggleStore,
  msalInstance,
  schemaStore,
  userStore,
} from "@mdr-ui/core";

export const useAuthPageInit = () => {
  const account = useAccount();
  const [isLoaded, setIsLoaded] = useState(false);

  const initApp = async () => {
    if (googleTagManagerArgs.gtmId) {
      TagManager.initialize(googleTagManagerArgs);
    }

    Promise.all([
      schemaStore.initSchema(),
      userStore.signIn(),
      featureToggleStore.init(),
    ]).finally(() => {
      if (!schemaStore.schema) {
        throw new Error(
          "Field Schema could not be loaded. Verify the API is running, and configured."
        );
      }
      if (!userStore.user) {
        throw new Error("User could not be loaded");
      }
      setIsLoaded(true);
    });
  };

  useEffect(() => {
    if (account != null) {
      initApp();
    } else {
      msalInstance.handleRedirectPromise().then(async (loggedInAccount) => {
        const isLoggedIn = !!loggedInAccount;

        if (!isLoggedIn) {
          await msalInstance.loginRedirect();
        }
        initApp();
      });
    }
  }, [account]);

  return { account, isLoaded };
};
