import React from "react";
import { Navigate, useOutlet } from "react-router";
export interface PagesLayoutChildrenComponentProps {
  redirectPath: string;
}

const PagesLayoutChildrenComponent: React.FC<
  PagesLayoutChildrenComponentProps
> = (props) => {
  const outlet = useOutlet();

  return <>{outlet || <Navigate to={props.redirectPath} replace />}</>;
};

export const PagesLayoutChildren = PagesLayoutChildrenComponent;
