import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { Observer } from "mobx-react";
import logo from "../assets/Medicaid-Done-Right-Logo.png";
import React from "react";
import { AdminTabs } from "./AdminTabs";
import "./styles.scss";
import { Header, PageSidebar, reactPluginInstance } from "@mdr-ui/core";
import variables from "../config/variables";

const AdminPageComponent: React.FC = () => {
  return (
    <div className="page board-page">
      {/* Dont want page level observer */}
      <Observer>{() => <Header logo={logo} />}</Observer>
      <div className="content">
        <PageSidebar
          activePage="admin"
          featureToggleConfig_matchups={variables.featureToggleConfig.matchups}
        />
        <div className="page-main-content">
          <AdminTabs />
        </div>
      </div>
    </div>
  );
};

export const AdminPage = withAITracking(
  reactPluginInstance,
  AdminPageComponent,
  "AdminPage",
  "AI-flex-container"
);
