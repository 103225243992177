import "@blueprintjs/core/lib/css/blueprint.css";
import "@blueprintjs/icons/lib/css/blueprint-icons.css";
import "@blueprintjs/popover2/lib/css/blueprint-popover2.css";
import "@blueprintjs/datetime/lib/css/blueprint-datetime.css";
import "@blueprintjs/datetime2/lib/css/blueprint-datetime2.css";
import "normalize.css/normalize.css";
import Pages from "./pages";
import "./App.scss";
import { configure } from "mobx";
import { registerLicense } from "@syncfusion/ej2-base";
import classNames from "classnames";
import variables from "./config/variables";
// Register Syncfusion License
registerLicense(variables.syncfusionLicenseKey);

function App() {
  configure({
    disableErrorBoundaries: true,
  });

  return (
    <section
      className={classNames(
        "app-container",
        variables.theme ? `theme-${variables.theme}` : ""
      )}
    >
      <Pages />
    </section>
  );
}

export default App;
