import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { SkeletonComponent } from "@syncfusion/ej2-react-notifications";
import { Observer } from "mobx-react";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { Link } from "react-router-dom";
import "../styles.scss";
import logo from "../../assets/Medicaid-Done-Right-Logo.png";
import { ApplicationActions } from "./ApplicationActions";
import EligibilityBanner from "./EligibilityBanner";
import {
  ApplicationFormPage,
  useTitle,
  errorStore,
  genericErrorMessage,
  userStore,
  applicationStore,
  Header,
  PageSidebar,
  permissionStore,
  applicationFormCards,
  reactPluginInstance,
  ApplicationFormProgress,
  featureToggleStore,
} from "@mdr-ui/core";
import variable from "../../config/variables";

const ApplicationFormComponent: React.FC = () => {
  const params = useParams<{ id: string; page: ApplicationFormPage }>();
  const navigate = useNavigate();
  const { setTitle } = useTitle("Medicaid Done Right");
  const [currentPage, setCurrentPage] = useState<ApplicationFormPage>(
    params.page || "primary-information"
  );

  useEffect(() => {
    if (!params.id) {
      errorStore.setErrorMessage(genericErrorMessage);
      return;
    }

    if (userStore.user?.canViewApplicationDetail) {
      applicationStore.getApplicationById(params.id);
    } else {
      navigate("/");
    }

    return () => {
      applicationStore.unsetApplication();
    };
  }, [params.id]);

  useEffect(() => {
    if (!params.page) {
      setCurrentPage("primary-information");
      return;
    }

    setCurrentPage(params.page);

    return () => setCurrentPage("primary-information");
  }, [params.page]);

  return (
    <div className="page application-form-page">
      <Observer>
        {() => (
          <>
            {setTitle(
              applicationStore.application
                ? `${applicationStore.application?.firstName} ${applicationStore.application?.lastName}`
                : "Medicaid Done Right"
            )}
            <Header
              logo={logo}
              breadCrumbs={[
                { text: "", children: <Link to="/">Board</Link> },
                {
                  text: applicationStore.application?.fileNumber ? (
                    `${applicationStore.application?.firstName} ${applicationStore.application?.lastName} - ${applicationStore.application?.fileNumber}`
                  ) : (
                    <SkeletonComponent
                      className="loading-skeleton"
                      type="rectangle"
                      width={150}
                      height={15}
                    />
                  ),
                },
              ]}
            />
            <div className="content">
              <PageSidebar
                activePage="home"
                featureToggleConfig_matchups={
                  variable.featureToggleConfig.matchups
                }
              />
              {applicationStore.application && (
                <div className="page-main-content">
                  <ApplicationFormProgress
                    hasMedicalCoverage={
                      applicationStore.application?.hasMedicalCoverage ===
                        true ||
                      applicationStore.application?.hasMedicarePartA === true ||
                      applicationStore.application?.hasMedicarePartB == true ||
                      applicationStore.application?.medicalCoverages?.length > 0
                    }
                    hasSpouse={applicationStore.application?.isMarried === true}
                    progress={applicationStore.application?.progress}
                    pageMissingFieldsCounters={
                      applicationStore.pageMissingFieldCount
                    }
                    currentPage={currentPage}
                    setCurrentPage={(page) => {
                      navigate(`/application-form/${params.id}/${page}`);
                    }}
                  />
                  <div className="application-card">
                    {(!applicationStore.application.isRecertProductType ||
                      (applicationStore.application.isRecertProductType &&
                        featureToggleStore.featureToggles?.EnableRecertAMD)) &&
                      applicationStore.eligibilitySummary && (
                        <EligibilityBanner
                          application={applicationStore.application}
                          eligibilitySummary={
                            applicationStore.eligibilitySummary
                          }
                        />
                      )}
                    <ApplicationActions
                      applicationId={applicationStore.application?.id}
                      isWithdrawn={applicationStore.application?.isWithdrawn}
                      canSubmitApplication={
                        permissionStore.canSubmitApplication &&
                        !!userStore.user?.canSubmitApplicationForm
                      }
                      canWithdrawApplicationForm={
                        !!userStore.user?.canWithdrawApplicationForm
                      }
                      canApplicationSubmitToState={
                        applicationStore.application
                          ?.canApplicationSubmitToState
                      }
                      canSubmitApplicationToState={
                        !!userStore.user?.canSubmitApplicationToState
                      }
                      hasApplicationStateSubmitted={
                        applicationStore.application
                          ?.hasApplicationStateSubmitted
                      }
                      canGenerateApplicationDocPackage={
                        !!userStore.user?.canGenerateApplicationDocPackage ||
                        !!userStore.user?.canGenerateDocsWhileClosed
                      }
                    />
                    {applicationFormCards[currentPage](
                      applicationStore.application
                    )}
                  </div>
                </div>
              )}
            </div>
          </>
        )}
      </Observer>
    </div>
  );
};

export const ApplicationForm = withAITracking(
  reactPluginInstance,
  ApplicationFormComponent,
  "ApplicationForm",
  "AI-flex-container"
);
