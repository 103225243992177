import logo from "../../assets/Medicaid-Done-Right-Logo.png";
import { AppointmentConfirmation } from "@mdr-ui/core";
interface AppointmentConfirmationPageProps {}

export const AppointmentConfirmationPage: React.FC<
  AppointmentConfirmationPageProps
> = () => {
  const queryParams = new URLSearchParams(location.search);

  return (
    <AppointmentConfirmation
      logo={logo}
      id={queryParams.get("id")}
      contactId={queryParams.get("contactId")}
      status={queryParams.get("status")}
      token={queryParams.get("token")}
    />
  );
};
