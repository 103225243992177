import React, { useEffect } from "react";
import { Navigate, useOutlet } from "react-router";
import "./styles.scss";
import { autorun } from "mobx";
import { AnonymousErrorWrapper, errorStore } from "@mdr-ui/core";
export interface PagesLayoutComponentProps {
  redirectPath: string;
}

const AnonymousPagesLayoutComponent: React.FC<PagesLayoutComponentProps> = (
  props
) => {
  const outlet = useOutlet();
  const [hasError, setHasError] = React.useState<boolean>(false);

  useEffect(() => {
    autorun(() => {
      setHasError(!!errorStore.errorMessage);
    });
  }, []);

  return (
    <>
      <div className="page">
        {hasError && (
          <AnonymousErrorWrapper
            error={undefined}
            resetErrorBoundary={() => {
              // do nothing
            }}
          />
        )}
        {!hasError && (
          <>{outlet || <Navigate to={props.redirectPath} replace />}</>
        )}
      </div>
    </>
  );
};

export const AnonymousPagesLayout = AnonymousPagesLayoutComponent;
