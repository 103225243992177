import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useLocation } from "react-router";
import logo from "../../assets/Medicaid-Done-Right-Logo.png";
import "../styles.scss";
import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { SelectEventArgs as SelectEventArgsAutoComplete } from "@syncfusion/ej2-react-dropdowns";
import {
  ExpandEventArgs,
  SelectEventArgs,
  TabComponent,
} from "@syncfusion/ej2-react-navigations";
import { AccordionComponent } from "@syncfusion/ej2-react-navigations";
import { isUndefined, orderBy } from "lodash";
import { Button, NonIdealState } from "@blueprintjs/core";
import {
  AutoCompleteComponent,
  FilteringEventArgs,
} from "@syncfusion/ej2-react-dropdowns";
import { PrimaryCard } from "@ucl/library";
import classNames from "classnames";
import { Observer } from "mobx-react";
import sanitizeHtml from "sanitize-html";
import {
  HelpCenterTopic,
  topicSectionTypeConfig,
  TopicSectionTypes,
  HelpCenterTopicState,
  HelpCenterSearchResponse,
  helpCenterTopicApiClient,
  AppToaster,
  genericErrorMessage,
  TopicSectionLayout,
  dialogsViewerStore,
  Header,
  PageSidebar,
  userStore,
  Loading,
  convertDateTo_EST_timeZone_AsString,
  reactPluginInstance,
} from "@mdr-ui/core";
import variables from "../../config/variables";

export interface HelpCenterPageComponentProps {
  tabId?: string;
  stateTabId?: string;
}

const HelpCenterPageComponent: React.FC<HelpCenterPageComponentProps> = (
  props
) => {
  //Navigation
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [topicParamValue, setTopicParamValue] = useState<string | null>();

  //Topics
  const [topics, setTopics] = useState<HelpCenterTopic[]>([]);
  const [isLoadingTopics, setIsLoadingTopics] = useState<boolean>();

  //Tabs
  const [tabId, setTabId] = useState(
    props.tabId || topicSectionTypeConfig[TopicSectionTypes.FAQ].path
  );
  const tabRef = useRef<TabComponent | null>(null);
  const supportRequestTabIndex = 6;
  const supportRequestPath = "submit-support-request";

  //State Tabs
  const [stateTabId, setStateTabId] = useState(props.stateTabId);
  const [topicStates, setTopicStates] = useState<HelpCenterTopicState[]>([]);
  const stateTabRef = useRef<TabComponent | null>(null);
  const [isLoadingStates, setIsLoadingStates] = useState<boolean>();

  //Accordion
  const accordionRef = useRef<AccordionComponent | null>(null);
  const [isExpanded, setIsExpanded] = React.useState<Record<number, boolean>>(
    {}
  );
  const [isActionIntent, setIsActionIntent] = React.useState<boolean>(false);
  const [isSearching, setIsSearching] = React.useState<boolean>(false);
  const isSearchingRef = React.useRef<boolean>(false);

  //Search
  const searchRef = useRef<AutoCompleteComponent | null>(null);
  const searchPromptBuilderRef = useRef<string>();
  const initialDataSource: {
    [key: string]: HelpCenterSearchResponse;
  }[] = [];

  const itemTemplate =
    "<table class='help-center-topic-search-result'><tr><td><div class='help-center-topic-search-title'>${helpCenterTopic_Title}</div><div class='help-center-topic-search-preview'>${helpCenterTopic_Content}</div></td></tr></table>";

  useEffect(() => {
    const queryParamId = queryParams.get("t");
    if (queryParamId) {
      setTopicParamValue(queryParamId);
    }
  }, []);

  useEffect(() => {
    setIsExpanded({});
  }, [topics.length]);

  useEffect(() => {
    if (searchRef.current) {
      (searchRef.current.element as HTMLInputElement).addEventListener(
        "keypress",
        (e) => {
          if (e.key === "Enter") {
            search(searchPromptBuilderRef.current);

            e.preventDefault();
            e.stopPropagation();
          }
        }
      );
    }
  }, [searchRef.current]);

  const search = async (searchValue?: string) => {
    if (!isSearchingRef.current && searchRef.current && searchValue) {
      isSearchingRef.current = true;
      setIsSearching(true);

      const res = await helpCenterTopicApiClient
        .searchHelpCenterTopics(searchValue)
        .catch(() => {
          AppToaster.show({
            message: genericErrorMessage,
            intent: "danger",
          });

          isSearchingRef.current = false;
          setIsSearching(false);
          return [];
        });

      // Map
      const data = res.map((searchResponse) => {
        return {
          helpCenterTopic_Id: searchResponse.helpCenterTopic_Id,
          helpCenterTopic_SectionType:
            searchResponse.helpCenterTopic_SectionType,
          helpCenterTopic_Title: searchResponse.helpCenterTopic_Title,
          helpCenterTopic_Content: previewHtml(
            searchResponse.helpCenterTopic_Content
          ),
          helpCenterTopic_UsState_AsString:
            searchResponse.helpCenterTopic_UsState_AsString,
        };
      });

      // Bind
      searchRef.current.dataSource = data;
      searchRef.current.filter(data);

      searchRef.current.hidePopup();
      setTimeout(() => {
        searchRef.current?.showPopup();
        isSearchingRef.current = false;
        setIsSearching(false);
      }, 100);
    }
  };

  // Tabs
  const getSelectedTabIndex = (tabId: string) => {
    if (tabId === supportRequestPath) {
      return supportRequestTabIndex;
    }

    const selectedTabIndex = Object.values(topicSectionTypeConfig).find(
      (config) => config.path.toLowerCase() === tabId.toLowerCase()
    )?.helpCenterTabIndex;

    return (
      selectedTabIndex ||
      topicSectionTypeConfig[TopicSectionTypes.FAQ].helpCenterTabIndex
    );
  };

  const tabSelected = (args: SelectEventArgs) => {
    const selectedTabId = getSelectedTabId(args.selectedIndex);
    setTopics([]);
    setTopicStates([]);
    setStateTabId(undefined);
    setTabId(
      selectedTabId || topicSectionTypeConfig[TopicSectionTypes.FAQ].path
    );
    setTopicParamValue(null);
    navigate(`/helpcenter/${selectedTabId}`);
  };

  const stateTabSelected = (args: SelectEventArgs) => {
    const selectedStateTabId = getSelectedStateTabId(args.selectedIndex);
    setTopics([]);
    setTopicStates([]);
    selectedStateTabId && setStateTabId(selectedStateTabId);
    setTopicParamValue(null);
    navigate(`/helpcenter/${tabId}/${selectedStateTabId}`);
  };

  const getSelectedStateTabId = (tabIndex: number) => {
    const selectedTabId = Object.values(topicStates).find(
      (topicState) => topicState.tabIndex === tabIndex
    );
    return selectedTabId?.usState_AsString.toLowerCase();
  };

  const getSelectedState = (stateId: string | undefined) => {
    if (!stateId) {
      return topicStates[0];
    }

    const selectedState = Object.values(topicStates).find(
      (stateTabIndex) =>
        stateTabIndex?.usState_AsString.toLowerCase() === stateId.toLowerCase()
    );

    return selectedState;
  };

  const getSelectedStateTabIndex = (tabId: string | undefined) => {
    const selectedTabIndex = getSelectedState(tabId);

    return selectedTabIndex?.tabIndex || 0;
  };

  const createStateTopicTabIndexes = (
    fetchedStateTopics: HelpCenterTopicState[]
  ) => {
    fetchedStateTopics.forEach((stateTopic, index) => {
      stateTopic.tabIndex = index;
    });
    setTopicStates(fetchedStateTopics);
    return fetchedStateTopics;
  };

  const getSelectedTabId = (tabIndex: number) => {
    if (tabIndex === supportRequestTabIndex) {
      return supportRequestPath;
    }

    const selectedTabId = Object.values(topicSectionTypeConfig).find(
      (config) => config.helpCenterTabIndex === tabIndex
    )?.path;

    return selectedTabId;
  };

  const expandedHandler = (args: ExpandEventArgs) => {
    if (!isUndefined(args.index)) {
      setIsExpanded({
        [args.index]: args.isExpanded || false,
      });
    }
  };

  const expandingHandler = (args: ExpandEventArgs) => {
    if (isActionIntent) {
      args.cancel = true;
    }
  };

  // Search
  const handleSearchSelect = (args: SelectEventArgsAutoComplete) => {
    args.cancel = true;

    const topic = args.itemData as HelpCenterSearchResponse;

    const redirectString = `/helpcenter/${
      topicSectionTypeConfig[topic.helpCenterTopic_SectionType].path
    }${
      topicSectionTypeConfig[topic.helpCenterTopic_SectionType].filterByUsState
        ? "/" + topic.helpCenterTopic_UsState_AsString?.toLowerCase()
        : ""
    }`;

    const url = `${redirectString}?t=${topic.helpCenterTopic_Id}&chatMinimized=true`;

    window.open(url);
  };

  const handleValueChange = (newValue: string) => {
    searchPromptBuilderRef.current = newValue;
    search(newValue);
  };

  const handleFiltering = (args: FilteringEventArgs) => {
    args.preventDefaultAction = true;
    searchPromptBuilderRef.current = args.text;
  };

  const getTopicIndex = (topicId: string): number => {
    const topicIndex = topics.findIndex((topic) => topic.id === topicId);
    return topicIndex;
  };

  const scrollIntoTopic = (topicType: TopicSectionTypes, topicId?: string) => {
    if (!accordionRef.current) {
      return;
    }
    if (!topicId) {
      return;
    }

    const topicConfig = topicSectionTypeConfig[topicType as TopicSectionTypes];

    let topicElement = undefined;
    if (topicConfig.sectionLayout === TopicSectionLayout.Accordion) {
      const topicElementContent = document.querySelector(`.topic_${topicId}`);

      if (topicElementContent) {
        topicElement = topicElementContent.closest(".e-acrdn-item");
      }
    } else {
      topicElement = document.querySelector(`#topic-${topicId}`);
    }

    if (topicElement) {
      topicElement.classList.add("searched-topic");

      topicElement.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  useEffect(() => {
    if (tabId !== supportRequestPath) {
      fetchTopics();
    }
  }, [tabId, stateTabId]);

  const getTopicType = (): TopicSectionTypes => {
    const topicTypeToFetch =
      Object.values(topicSectionTypeConfig).find((x) => x.path === tabId)
        ?.key || topicSectionTypeConfig[TopicSectionTypes.FAQ].key;
    return topicTypeToFetch;
  };

  const fetchTopicsStates = async () => {
    if (isLoadingStates) {
      return;
    }

    const topicTypeToFetch = getTopicType();

    if (!!topicTypeToFetch) {
      if (topicSectionTypeConfig[topicTypeToFetch].filterByUsState) {
        setIsLoadingStates(true);
        const fetchedTopicStates = await helpCenterTopicApiClient
          .getHelpCenterTopicStatesByType([topicTypeToFetch])
          .catch(() => {
            AppToaster.show({
              message: genericErrorMessage,
              intent: "danger",
            });
          });

        if (!fetchedTopicStates) {
          return;
        }
        const indexedTopicStates =
          createStateTopicTabIndexes(fetchedTopicStates);
        setIsLoadingStates(false);
        return indexedTopicStates;
      }
    }
  };

  const fetchTopics = async () => {
    if (isLoadingTopics) {
      return;
    }

    setIsLoadingTopics(true);
    const topicTypeToFetch = getTopicType();

    if (!!topicTypeToFetch && !!topicStates) {
      const localTopicStates = await fetchTopicsStates();

      let stateToSearchBy: number | undefined = 0;
      if (stateTabId && localTopicStates) {
        stateToSearchBy = localTopicStates.find(
          (x) => x.usState_AsString.toLowerCase() === stateTabId.toLowerCase()
        )?.usState;
      } else if (localTopicStates) {
        stateToSearchBy = localTopicStates[0]?.usState;
      } else {
        stateToSearchBy = topicStates[0]?.usState;
      }

      const fetchedTopics = await helpCenterTopicApiClient
        .getHelpCenterTopicsByType(topicTypeToFetch, stateToSearchBy)
        .catch(() => {
          AppToaster.show({
            message: genericErrorMessage,
            intent: "danger",
          });
        })
        .finally(() => {
          setIsLoadingTopics(false);
        });

      if (!fetchedTopics) {
        return;
      }

      // Set topics
      setTopics(fetchedTopics);

      if (queryParams.has("t")) {
        queryParams.delete("t");
        navigate({ search: queryParams.toString() });
      }
    }
  };

  const decodeHtml = (htmlString: string) => {
    try {
      const decoded = window.atob(htmlString.toString());
      return decoded;
    } catch (e) {
      return "";
    }
  };

  const previewHtml = (htmlString: string): string => {
    if (!htmlString) {
      return "";
    }

    try {
      htmlString = decodeHtml(htmlString.toString());
    } catch (e) {
      return "";
    }

    // Remove all HTML tags except <h1> headers
    let strippedText = htmlString.replace(/<(?!\/?h1)[^>]+>/gi, " ");

    // Remove all &nbsp; characters
    strippedText = strippedText.replace(/&nbsp;/gi, "");

    // Replace <h1> headers with <strong> tags
    const modifiedHtml = strippedText.replace(
      /<h1>(.*?)<\/h1>/gi,
      "<strong>$1</strong>"
    );

    return modifiedHtml.substring(0, 800);
  };

  const openUpdateTopicDialog = (topicId: string) => {
    const topicTypeToFetch = getTopicType();

    dialogsViewerStore.setIsHelpCenterTopicDialogOpen(true, {
      sectionType: topicTypeToFetch,
      id: topicId,
      onClose: async (refresh: boolean) => {
        dialogsViewerStore.setIsHelpCenterTopicDialogOpen(false);

        if (refresh) {
          await fetchTopics();
        }
      },
    });
  };

  const openCreateTopicDialog = () => {
    const topicTypeToFetch = getTopicType();

    dialogsViewerStore.setIsHelpCenterTopicDialogOpen(true, {
      sectionType: topicTypeToFetch,
      usState: topicSectionTypeConfig[topicTypeToFetch].filterByUsState
        ? getSelectedState(stateTabId)?.usState
        : undefined,
      onClose: async (refresh: boolean) => {
        dialogsViewerStore.setIsHelpCenterTopicDialogOpen(false);

        if (refresh) {
          await fetchTopics();
        }
      },
    });
  };

  const accordionCreatedHandler = (sectionType: TopicSectionTypes) => {
    if (topicParamValue) {
      const topicType = getTopicType();
      if (!topicType) {
        return;
      }
      if (topicType !== sectionType) {
        return;
      }

      // Scroll Into Topic
      // Expanded is setup via ExpandedIndexes on Accordion
      scrollIntoTopic(topicType, topicParamValue);
    }
  };

  return (
    <div className="page board-page helpcenter-page">
      <Observer>{() => <Header logo={logo} />}</Observer>
      <div className="content">
        <PageSidebar
          activePage="helpcenter"
          featureToggleConfig_matchups={variables.featureToggleConfig.matchups}
        />
        {userStore.user?.canViewHelpCenter && (
          <div className="page-main-content">
            <div className="helpcenter-container">
              <section className="helpcenter-header">
                <h1 className="helpcenter-header__title">Help Center</h1>
                <div className="helpcenter-header__description">
                  We&apos;ve anticipated some questions you might have and
                  provided answers to make things easier for you. Feel free to
                  browse through or use the keyword search below to find what
                  you&apos;re looking for. If you don&apos;t find the answer you
                  need, just submit a request form and we&apos;ll be more than
                  happy to help.
                  <p>
                    <b>Disclaimer:</b> These guidelines are for informational
                    purposes only and do not constitute legal advice.
                  </p>
                </div>
              </section>
              <section className="helpcenter-search base-field">
                <AutoCompleteComponent
                  ref={searchRef}
                  cssClass="helpcenter-search__input"
                  placeholder="What do you need help with?"
                  allowCustom={false}
                  dataSource={initialDataSource}
                  readOnly={isSearching}
                  value={""}
                  onChange={(e: { value: string }) => {
                    handleValueChange(e.value);
                  }}
                  onBlur={() => {
                    if (searchRef.current) {
                      (searchRef.current.element as HTMLInputElement).value =
                        searchPromptBuilderRef.current || "";
                    }
                  }}
                  select={handleSearchSelect}
                  fields={{
                    text: "helpCenterTopic_Title",
                    value: "helpCenterTopic_Id",
                  }}
                  itemTemplate={itemTemplate}
                  showClearButton={false}
                  filtering={handleFiltering}
                ></AutoCompleteComponent>
                <Button
                  className="helpcenter-search__button"
                  text="Search"
                  intent="primary"
                  minimal={true}
                  disabled={isSearching}
                  loading={isSearching}
                  onClick={() => {
                    search(searchPromptBuilderRef.current);
                  }}
                />
              </section>
              <section className="helpcenter-sections">
                <div className="control_wrapper" id="control_wrapper">
                  <TabComponent
                    ref={tabRef}
                    headerPlacement="Top"
                    selected={tabSelected}
                    selectedItem={getSelectedTabIndex(tabId)}
                    overflowMode="MultiRow"
                    animation={{
                      previous: { effect: "FadeIn" },
                      next: { effect: "FadeIn" },
                    }}
                  >
                    <div className="e-tab-header">
                      {Object.values(
                        orderBy(
                          topicSectionTypeConfig,
                          (config) => config.helpCenterTabIndex,
                          "asc"
                        )
                      ).map((config) => (
                        <div key={config.shortTitle}>{config.title}</div>
                      ))}
                      <div>Submit Support Request</div>
                    </div>
                    <div className="e-content">
                      {Object.values(
                        orderBy(
                          topicSectionTypeConfig,
                          (config) => config.helpCenterTabIndex,
                          "asc"
                        )
                      ).map((config) => (
                        <div key={config.shortTitle}>
                          <div
                            key={
                              config.shortTitle +
                              topics.map((x) => JSON.stringify(x)).toString()
                            }
                            className={classNames("control_wrapper", {
                              "help-center-topics-no-data":
                                !isLoadingTopics && topics?.length <= 0,
                            })}
                            id="control_wrapper"
                          >
                            {config.key !== getTopicType() && <></>}
                            {config.key === getTopicType() &&
                              isLoadingTopics && <Loading />}
                            {config.key === getTopicType() &&
                              !isLoadingTopics &&
                              userStore.user?.canManageHelpCenterTopics && (
                                <div className="help-center-topics-toolbar">
                                  <Button
                                    className="help-center-topics-toolbar--actions-create"
                                    icon="plus"
                                    intent="primary"
                                    minimal={true}
                                    onClick={openCreateTopicDialog}
                                  >
                                    Create
                                  </Button>
                                </div>
                              )}
                            {config.key === getTopicType() &&
                              !isLoadingTopics &&
                              topics?.length <= 0 && (
                                <NonIdealState title="No Data Available" />
                              )}
                            <div className="help-center-topics-items">
                              {config.key === getTopicType() &&
                                config.filterByUsState === true &&
                                topics?.length > 0 &&
                                !isLoadingStates &&
                                topicStates?.length > 0 && (
                                  <TabComponent
                                    ref={stateTabRef}
                                    headerPlacement="Top"
                                    selected={stateTabSelected}
                                    selectedItem={getSelectedStateTabIndex(
                                      stateTabId
                                    )}
                                    overflowMode="Popup"
                                    animation={{
                                      previous: { effect: "FadeIn" },
                                      next: { effect: "FadeIn" },
                                    }}
                                  >
                                    <div className="e-tab-header">
                                      {Object.values(
                                        orderBy(
                                          topicStates,
                                          (topicState) =>
                                            topicState.usState_AsString,
                                          "asc"
                                        )
                                      ).map((topicState) => (
                                        <div key={topicState.usState_AsString}>
                                          {topicState.usState_AsString}
                                        </div>
                                      ))}
                                    </div>
                                    <div className="e-content"></div>
                                  </TabComponent>
                                )}
                              {config.key === getTopicType() &&
                                !isLoadingTopics &&
                                topics?.length > 0 &&
                                config.sectionLayout ===
                                  TopicSectionLayout.Accordion && (
                                  <AccordionComponent
                                    key={config.shortTitle}
                                    ref={accordionRef}
                                    expandMode="Single"
                                    expandedIndices={
                                      topicParamValue
                                        ? [getTopicIndex(topicParamValue)]
                                        : []
                                    }
                                    created={() =>
                                      accordionCreatedHandler(config.key)
                                    }
                                    expanding={expandingHandler}
                                    expanded={expandedHandler}
                                  >
                                    {topics.map((topic, index) => (
                                      <div key={topic.id}>
                                        <div
                                          className={classNames(
                                            "help-center-topic-header",
                                            `topic_${topic.id}`
                                          )}
                                        >
                                          <div className="help-center-topic-header__title">
                                            {topic.title}
                                          </div>
                                          <div
                                            className="help-center-topic-header__content help-center-topic-header__content--preview"
                                            dangerouslySetInnerHTML={{
                                              __html: previewHtml(
                                                topic.content
                                              ),
                                            }}
                                          />
                                          {isExpanded[index] && (
                                            <div className="help-center-topic-header__content help-center-topic-header__content--actions">
                                              <div className="help-center-topic-header__content--actions-metadata">
                                                <b>Last Updated:</b>
                                                {` ${convertDateTo_EST_timeZone_AsString(
                                                  topic.updatedAt
                                                )}
                                              | ${topic.updatedBy_AsName}
                                              `}
                                              </div>
                                              {userStore.user
                                                ?.canManageHelpCenterTopics && (
                                                <Button
                                                  className="help-center-topic-header__content--actions-edit"
                                                  icon="edit"
                                                  intent="primary"
                                                  minimal={true}
                                                  onMouseEnter={() => {
                                                    setIsActionIntent(true);
                                                  }}
                                                  onMouseLeave={() => {
                                                    setIsActionIntent(false);
                                                  }}
                                                  onClick={() =>
                                                    openUpdateTopicDialog(
                                                      topic.id
                                                    )
                                                  }
                                                >
                                                  Edit
                                                </Button>
                                              )}
                                            </div>
                                          )}
                                        </div>
                                        <div>
                                          <div
                                            dangerouslySetInnerHTML={{
                                              __html: topic.content
                                                ? sanitizeHtml(
                                                    decodeHtml(topic.content)
                                                  )
                                                : "",
                                            }}
                                          />
                                          {topic.externalLink && (
                                            <a
                                              href={topic.externalLink}
                                              target="_blank"
                                              rel="noopener noreferrer"
                                            >
                                              {topic.externalLink}
                                            </a>
                                          )}
                                        </div>
                                      </div>
                                    ))}
                                  </AccordionComponent>
                                )}
                              {topics &&
                                topics.length > 0 &&
                                config.sectionLayout ===
                                  TopicSectionLayout.Card && (
                                  <div
                                    key={config.shortTitle}
                                    className="control_wrapper"
                                    id="control_wrapper"
                                  >
                                    {!topics && <Loading />}
                                    {topics && topics.length <= 0 && (
                                      <div>No topics found</div>
                                    )}
                                    {topics && topics.length > 0 && (
                                      <div
                                        key={config.shortTitle}
                                        className="card-topics-grid"
                                      >
                                        {topics.map((topic) => (
                                          <PrimaryCard
                                            className="card-topic-card"
                                            key={topic.id}
                                            id={`topic-${topic.id}`}
                                          >
                                            <div className="card-topic-content">
                                              <div className="card-topic-content__title">
                                                {topic.title}
                                              </div>
                                              {userStore.user
                                                ?.canManageHelpCenterTopics && (
                                                <div className="card-topic-content__actions">
                                                  <Button
                                                    className="card-topic-content__actions-edit"
                                                    icon="edit"
                                                    intent="primary"
                                                    minimal={true}
                                                    onClick={() =>
                                                      openUpdateTopicDialog(
                                                        topic.id
                                                      )
                                                    }
                                                  >
                                                    Edit
                                                  </Button>
                                                </div>
                                              )}

                                              <div className="card-topic-content__link">
                                                {topic.externalLink && (
                                                  <Button
                                                    icon={config.icon}
                                                    intent="primary"
                                                    minimal={true}
                                                    onClick={() => {
                                                      window.open(
                                                        topic.externalLink,
                                                        "_blank",
                                                        "noopener,noreferrer"
                                                      );
                                                    }}
                                                  >
                                                    View {config.singularTitle}
                                                  </Button>
                                                )}
                                              </div>
                                            </div>
                                          </PrimaryCard>
                                        ))}
                                      </div>
                                    )}
                                  </div>
                                )}
                            </div>
                          </div>
                        </div>
                      ))}
                      <div className="control_wrapper" id="control_wrapper">
                        {tabId === supportRequestPath && (
                          <PrimaryCard headerContent="Submit Support Request">
                            <iframe src="https://forms.clickup.com/45027226/f/1ay3wu-130/BFWSMDBBI0OZQ46ZU0" />
                          </PrimaryCard>
                        )}
                      </div>
                    </div>
                  </TabComponent>
                </div>
              </section>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export const HelpCenterPage = withAITracking(
  reactPluginInstance,
  HelpCenterPageComponent,
  "HelpCenterPage",
  "AI-flex-container"
);
