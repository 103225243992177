import { Observer } from "mobx-react";
import React, { useEffect } from "react";
import { useNavigate } from "react-router";
import report_background from "../assets/report_background.png";
import logo from "../assets/Medicaid-Done-Right-Logo.png";
import "./styles.scss";
import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { Button, H2, NonIdealState } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import {
  useReport,
  AppToaster,
  genericErrorMessage,
  dialogsViewerStore,
  Header,
  PageSidebar,
  userStore,
  Loading,
  reactPluginInstance,
  Report,
} from "@mdr-ui/core";
import variables from "../config/variables";

const ReportsPageComponent: React.FC = () => {
  const navigate = useNavigate();

  const [isLoadingReports, setIsLoadingReports] = React.useState<boolean>();
  const [reports, setReports] = React.useState<Report[]>([]);

  const { getReports } = useReport();

  useEffect(() => {
    fetchReports();
  }, []);

  const fetchReports = async () => {
    if (isLoadingReports) {
      return;
    }

    setIsLoadingReports(true);

    const fetchedReports = await getReports()
      .catch(() => {
        AppToaster.show({
          message: genericErrorMessage,
          intent: "danger",
        });
      })
      .finally(() => {
        setIsLoadingReports(false);
      });

    if (!fetchedReports) {
      return;
    }

    // Set reports
    setReports(fetchedReports);
  };

  const openUpdateReportDialog = (topicId: string) => {
    dialogsViewerStore.setIsReportDialogOpen(true, {
      id: topicId,
      onClose: async (refresh: boolean) => {
        dialogsViewerStore.setIsReportDialogOpen(false);

        if (refresh) {
          await fetchReports();
        }
      },
    });
  };

  const openCreateReportDialog = () => {
    dialogsViewerStore.setIsReportDialogOpen(true, {
      onClose: async (refresh: boolean) => {
        dialogsViewerStore.setIsReportDialogOpen(false);

        if (refresh) {
          await fetchReports();
        }
      },
    });
  };

  return (
    <div className="page board-page reports-page">
      <Observer>{() => <Header logo={logo} />}</Observer>
      <div className="content">
        <PageSidebar
          activePage="reports"
          featureToggleConfig_matchups={variables.featureToggleConfig.matchups}
        />
        <div className="page-main-content">
          <div className="reports-page__header">
            <h1 className="reports-page-header__title">Reports</h1>
            {userStore.user?.canManageReports && (
              <Button
                className="reports-page__header--actions-create"
                icon={IconNames.PLUS}
                intent="primary"
                minimal={true}
                onClick={() => openCreateReportDialog()}
              >
                Create Report
              </Button>
            )}
          </div>
          <div className="reports-page__reports">
            {isLoadingReports && <Loading />}
            {!isLoadingReports && (!reports || reports.length <= 0) && (
              <NonIdealState title="No Reports Available" />
            )}
            {!isLoadingReports && reports && reports.length > 0 && (
              <>
                {reports.map((report) => {
                  return (
                    <div
                      key={report.id}
                      className="reports-page__reports__report-item"
                      style={{ backgroundImage: `url(${report_background})` }}
                    >
                      <div className="reports-page__reports__report-item__overlay-container">
                        <H2 className="reports-page__reports__report-item__title">
                          {report.title}
                        </H2>
                        {userStore.user?.canManageReports && (
                          <Button
                            className="reports-page__reports__report-item--actions-edit"
                            icon="edit"
                            intent="primary"
                            minimal={true}
                            onClick={() => openUpdateReportDialog(report.id)}
                          >
                            Edit
                          </Button>
                        )}
                        <Button
                          minimal
                          intent="primary"
                          outlined
                          text="View Report"
                          icon={IconNames.EYE_OPEN}
                          onClick={() => {
                            navigate(`/reports/${report.id}`);
                          }}
                        />
                      </div>
                    </div>
                  );
                })}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export const ReportsPage = withAITracking(
  reactPluginInstance,
  ReportsPageComponent,
  "ReportsPage",
  "AI-flex-container"
);
